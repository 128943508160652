import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import {StateConsumer} from '../components/StateContext'
import Wordlist from "../components/Wordlist/Wordlist"
import Breadcrumb from "../components/Breadcrumb/Breadcrumb"
import MetaTags from "../components/MetaTags"
import Logo from "../components/Logo/Logo"


export class WordListPageTemplate extends Component {


  render() {
    const {data} = this.props

    if(!data) return null

    const {
      /*page,*/
      words
    } = data


    return (
      <StateConsumer>{stateContext =>
        <>
          <MetaTags title="Ordliste"/>
          <section>
            <div className="container">
              <div className="row">
                <div className="columns col-xs-12 col-md-9">
                <Breadcrumb to={"/soeg"} globals={stateContext} />
                </div>
              
              </div>
            </div>
          </section>
        
          <Wordlist
            globals={stateContext}
            words={words.edges}
          />
           <Logo />
                
        </>
      }
      </StateConsumer>
    )
  }
}

WordListPageTemplate.propTypes = {
  data: PropTypes.object
}

const WordListPage = (props) => {
  const {data} = props
  console.log('props', props)

  return (
    <WordListPageTemplate data={data}/>
  )
}

WordListPage.propTypes = {
  data: PropTypes.object
}

export default WordListPage

export const pageQuery = graphql`
  query WordListPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      frontmatter {
        templateKey
        title
      }
    }
    words: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title,] },
      filter: { frontmatter: { templateKey: { eq: "word-page" } }}
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`

