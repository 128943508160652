import React, {Component} from 'react'
import styles from './Breadcrumb.module.css'
import {Link} from "@reach/router"


class Breadcrumb extends Component {


  render() {
    
    const url = this.props.to ? this.props.to : this.props.globals.getBreadcrumbUrl();

    return (
      <div className={styles.Breadcrumb}>
        <Link to={url} className={styles.Link}>❮</Link> 
      </div>
    )
  }
}

export default Breadcrumb
