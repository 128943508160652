import React, {Component} from 'react';
import styles from './Wordlist.module.css';
import {Link} from "@reach/router"
import Fade from 'react-reveal/Fade'; 
import smoothscroll from 'smoothscroll-polyfill';


class Wordlist extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filteredResults: [],
      alphabetResults: {}
    }
  }


  componentDidMount(){

    smoothscroll.polyfill();

    const {
      words
    } = this.props

    const allWords = words.map(w => w.node);

    // Create alphabetically groups
    var groups = [];

    allWords.forEach(function(word) {
      var letter = word.frontmatter.title[0];

      if(!groups[letter])
      {
        groups[letter] = [];
      }

      groups[letter].push(word);
    });

    var filteredGroups = [];

    Object.keys(groups).forEach(key => {
      const alphabetGroup = {
        startletter: key,
        letters: groups[key]
      }

      filteredGroups.push(alphabetGroup);
  })  

 
    this.setState({
      alphabetResults: filteredGroups
    })
  }



  render() {
    const {alphabetResults} = this.state

    const scrollTo = (event, id) => {

      event.preventDefault();

      function offset(el){
          var rect = el.getBoundingClientRect(),
          scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
          scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
      }

      var element = document.querySelector('#' + id);
      var divOffset = offset(element);

      window.scrollTo({
        top: divOffset.top,
        behavior: 'smooth'
      })

    }
   
    
    return (
      <>
        <section className={styles.Wordlist}>
        <div className="container">

                {alphabetResults && alphabetResults.length > 0 &&
                  <div className="row">
                    <div className={styles.LetterLinks}>
                
                      <div className={styles.Columns + " columns col-xs-12"}>
                  
                        <Fade bottom distance={"10px"}>
                          <ul className={styles.LetterLinksList}>
                          {alphabetResults.map((r, idx) => (
                            <li key={idx}>
                              <a href={"#" + r.startletter} onClick={(event) => {scrollTo(event, r.startletter)}}>{r.startletter}</a>        
                            </li> 
                          ))
                        }
                          </ul>

                          </Fade>
                        
                      </div>
                    </div>
                  </div>
                }

                <div className={styles.Results}>
                  {alphabetResults && alphabetResults.length > 0 &&
                  alphabetResults.map((r, idx) => (
         
                    <div id={r.startletter} key={idx} className={styles.AlphabetRow + " row"}>
                      <div className="columns col-xs-12 col-md-2">
                        <div className={styles.Startletter}>{r.startletter}</div> 
                      </div>
                      <div className="columns col-xs-12 col-sm-10">
                          <Fade bottom cascade distance={"40px"}>
                            <ul className={styles.list}>
                              {r.letters && r.letters.length > 0 &&
                              r.letters.map((r, idx) => (
                                <li key={idx} className={styles.listItem}>
                                  <Link className="link" to={r.fields.slug}>{r.frontmatter.title}</Link>

                                </li>
                              ))
                              }
                            </ul>
                          </Fade>
                      </div> 
                  </div>      
             
                  ))
                  }
                </div>
       
           
          </div>
        </section>
   

      </>
    )
  }
}

export default Wordlist;
